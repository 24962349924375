import React from 'react'
import './Featured.css'
import Banner from '../Assets/elektrik-banner.png'



const Featured = () => {
  return (
    <div className="featured">
        <div className="container">
            <div className="py3 g2 mb3">
                <div>
                    <img className='multimetre' src={Banner} alt="" width={600} />
                </div>
                <div>
                    <h2 className='syf-bslk pb4' >Elektrik, Elektronik Ve Otomasyon Alanında Uzman</h2>
                    <div className='ft-about'>
                        <div className="seperator"></div>
                        <p className='txt'>İnallar Elektrik Elektronik ve Otomasyon, 2023 yılında kurulmuş bir şirkettir. Müşterilerimize, endüstriyel ve evsel ihtiyaçlar için yenilikçi elektrik, elektronik ve otomasyon çözümleri sunuyoruz. Müşteri memnuniyetini ve teknolojik ilerlemeyi ön planda tutarak, sektördeki konumumuzu en üst seviyeye taşımaya ve geliştirmeye devam ediyoruz. Uzman kadromuz ve müşteri odaklı yaklaşımımızla, geleceğin teknolojisini bugünden şekillendiriyoruz.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Featured