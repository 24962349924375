import React from 'react'
import './Banner.css'

const Banner = () => {
  return (
    <div className="banner  pb3">
        <div className="container">
            <div className="icerik py1 g2c ">
                <h2 className='bnnr-bslk'>Hizmetlerimizden Faydalanmak için Bizimle İletişime Geçin!</h2>
                <a href="tel:05424704215" style={{display:'inline'}}>
                    <button className='btn' data-label="İletişime Geç"> İletişime Geç
                    </button>
                </a>
            </div>
        </div>
    </div>
  )
}

export default Banner