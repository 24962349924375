import React from 'react'
import './Blog.css'
import BreadCrumps from '../BreadCrumps/BreadCrumps'


const Elektronik = () => {
  return (
    <div>
    <BreadCrumps  page="Elektronik Çözümleri"  baslik="Elektronik Çözümleri" text="Profesyonel, hızlı ve çözüm odaklı yaklaşım."/>
    <div className="container">
      <div className="blog">
        <div className="blog-text">
          <h2 className='mb1'>Elektronik Çözümleri</h2>
            <p>Çorlu Elektronik olarak, çağımızın vazgeçilmez unsuru haline gelen elektronik alanında uzmanlaşmış bir ekip olarak, müşterilerimize güvenilir ve kaliteli elektronik çözümler sunuyoruz. Teknolojinin hızla ilerlediği günümüzde, elektronik sistemlerin güvenliği ve verimliliği büyük önem taşımaktadır. Biz de bu bilinçle hareket ederek müşterilerimize en iyi hizmeti sunmayı hedefliyoruz..</p>
            <br />
          <h2 className='mb1'>Elektronik Ustası Nedir ve Hangi Hizmetleri Verir?</h2>
            <p>Elektronik ustaları, elektronik sistemlerin kurulumu, bakımı ve onarımı gibi konularda uzmanlaşmış profesyonellerdir. Bu alanlarda geniş bir bilgi birikimine ve deneyime sahip olan elektronik ustaları, müşterilerin elektronik ihtiyaçlarını karşılamak için çeşitli hizmetler sunarlar.</p>
            <br />
            <p>hizmetler arasında elektronik sistemlerin kurulumu, arıza tespiti ve onarımı, güvenlik sistemlerinin entegrasyonu ve enerji verimliliği çözümleri bulunmaktadır.</p>
            <br />
          <h2 className='mb1'>Bizim Sunduğumuz Hizmetler</h2>

            <p><span style={{fontWeight:600}}>Elektronik Sistem Kurulumu ve Bakımı:</span> Çorlu Elektronik olarak, evlerden işyerlerine, sanayi tesislerinden kamu binalarına kadar her türlü elektronik sistem kurulumu ve bakımını titizlikle gerçekleştiriyoruz. Bu kapsamda güvenlik kamerası sistemleri, alarm sistemleri, akıllı ev otomasyon sistemleri, ses ve görüntü sistemleri gibi çeşitli elektronik sistemlerin kurulumunu ve bakımını yapıyoruz.</p>
            <br />
            <p><span style={{fontWeight:600}}>Elektronik Güvenlik Sistemleri Entegrasyonu: </span> Güvenlik, elektronik sistemlerle sıkı sıkıya bağlantılıdır. Bu nedenle, Çorlu Elektronik olarak, evlerde ve işyerlerinde güvenliğin sağlanması için gerekli olan güvenlik sistemlerinin kurulumu ve entegrasyonunu profesyonel bir şekilde gerçekleştiriyoruz. Alarm sistemleri, kamera sistemleri, hareket sensörleri gibi güvenlik ekipmanlarını doğru bir şekilde entegre ederek, müşterilerimizin güvenliğini en üst düzeye çıkarıyoruz.</p>
            <br />
            <p><span style={{fontWeight:600}}>Enerji Verimliliği Çözümleri:</span> Çorlu Elektronik olarak, çevreye duyarlılık ve maliyet tasarrufu için enerji verimliliği çözümleri sunuyoruz. Akıllı aydınlatma sistemleri, enerji yönetim sistemleri, güneş paneli sistemleri gibi çözümlerle müşterilerimizin enerji maliyetlerini düşürmelerine yardımcı oluyoruz. Bu sayede, sadece çevreye duyarlı bir yaşam tarzı benimsemekle kalmıyor, aynı zamanda müşterilerimize uzun vadeli maliyet tasarrufu sağlıyoruz.</p>
            <br />
            <h2 className='mb1'>Kurulum Hizmetleri</h2>
            <p>Çorlu Elektronik olarak, müşterilerimize en kaliteli kurulum hizmetlerini sunmak için buradayız. Elektronik sistemlerin doğru bir şekilde kurulması, güvenliği ve verimliliği sağlamak için hayati öneme sahiptir. Bu nedenle, elektronik sistemlerin kurulumunu uzman ekibimiz tarafından titizlikle gerçekleştiriyoruz. </p>
            <br />
            <p>Elektronik güvenlik sistemleri, ses ve görüntü sistemleri, akıllı ev otomasyon sistemlerine kadar her türlü elektronik sistem kurulumu ve bakımını gerçekleştiriyoruz. Müşterilerimizin ihtiyaçlarına ve beklentilerine uygun çözümler sunarak, elektronik sistemlerin en verimli şekilde çalışmasını sağlıyoruz.</p>
        </div>
        <div className="sidebar">
          <div className="widget">
            <div className="hizmet">
              <h2>Hizmetlerimiz</h2>
            </div>
            <div className="hizmet">
                <a href="/corlu-elektrikci">
                    <h3>Elektrik Çözümleri</h3>
                </a>
            </div>
            <div className="hizmet">
                <a href="/elektronik-cozumleri">
                    <h3>Elektronik Çözümleri</h3>
                </a>
            </div>
            <div className="hizmet">
                <a href="/otomasyon-sistemleri">
                    <h3>Otomasyon Sistemleri</h3>
                </a>
            </div>
            <div className="hizmet">
                <a href="/guvenlik-sistemleri">
                    <h3>Güvenlik Sistemleri</h3>
                </a>
            </div>
            <div className="hizmet">
                <a href="/kompanzasyon-sistemleri">
                    <h3>Kompanzasyon Sistemleri</h3>
                </a>
            </div>
            <div className="hizmet">
                <a href="/corlu-elektrikci">
                    <h3>Elektrik Çözümleri</h3>
                </a>
            </div>
          </div>
          <div className="sidebar-icerik">
          <h3 > Size Nasıl Yardımcı Olabiliriz?</h3>
          <p> Hakkımızda daha fazla bilgi almak isterseniz bizimle iletişime geçebilirsiniz.</p>
          <a href="tel:05424704215" style={{display:'inline'}}>
            <button className='btn' data-label="İletişime Geç"> İletişime Geç
            </button>

          </a>
          </div>
        </div>
      </div>
    </div>
</div>
  )
}

export default Elektronik