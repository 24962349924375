import React from 'react'
import './Blog.css'
import BreadCrumps from '../BreadCrumps/BreadCrumps'


const Guvenlik = () => {
  return (
    <div>
        <BreadCrumps  page="Güvenlik Sistemleri"  baslik="Güvenlik Sistemleri" text="Profesyonel, hızlı ve çözüm odaklı yaklaşım."/>
        <div className="container">
        <div className="blog">
            <div className="blog-text">
            <h2 className='mb1'>Güvenlik Sistemleri</h2>
                <p>Güvenlik, yaşam alanlarımızın önemli bir parçasıdır ve her geçen gün artan tehlikeler karşısında daha da önem kazanmaktadır. Güvenlik sistemleri, ev ve işyerlerimizi çeşitli tehlikelere karşı korumak için tasarlanmış teknolojik çözümlerdir. Gelişen teknolojiyle birlikte, güvenlik sistemleri daha da sofistike hale gelmiş ve yaşamımızı daha güvenli hale getirmiştir.</p>
                <br />
            <h2 className='mb1'>Güvenlik Sistemleri Nedir ve Nasıl Çalışır?</h2>
                <p>Güvenlik sistemleri, bir veya birden fazla bileşenden oluşan sistemlerdir ve ev veya işyeri gibi yaşam alanlarının güvenliğini sağlamak için kullanılır. Bu sistemler genellikle kamera sistemleri, alarm sistemleri, hareket sensörleri, kapı ve pencere sensörleri gibi bileşenleri içerir. Güvenlik sistemleri genellikle bir merkezi kontrol paneli tarafından yönetilir ve tehlike algılandığında alarm verir veya kayıt yapar.</p>
                <br />
            <h2 className='mb1'>Güvenlik Sistemlerinin Faydaları</h2>

                <p><span style={{fontWeight:600}}>Hırsızlık ve İzinsiz Giriş Önleme:</span> Güvenlik kameraları ve alarm sistemleri, ev veya işyerine izinsiz girişleri algılayarak hırsızlık ve diğer suçları önler.</p>
                <br />
                <p><span style={{fontWeight:600}}>Yangın ve Gaz Kaçağı Tespiti: </span> Yangın ve gaz kaçağı sensörleri, tehlikeli durumları algılayarak erken uyarı sağlar ve can ve mal kaybını önler.</p>
                <br />
                <p><span style={{fontWeight:600}}>Acil Durumlarda Yardım Çağrısı:</span> Panik düğmesi veya acil durum butonu gibi özellikler, acil durumlarda hızlı yardım çağrısı yapılmasını sağlar.</p>
                <br />
                <p><span style={{fontWeight:600}}>Uzaktan İzleme ve Kontrol:</span> Akıllı güvenlik sistemleri, uzaktan izleme ve kontrol imkanı sunarak kullanıcılara ev veya işyerlerini her yerden izleme ve kontrol etme imkanı sağlar.</p>
                <br />
                <h2 className='mb1'>Güvenlik Sistemlerinin Uygulama Alanları</h2>
                <p><span style={{fontWeight:600}}>Ev Güvenlik Sistemleri:</span> Evlerde kullanılan güvenlik sistemleri, hırsızlık, yangın, gaz kaçağı gibi tehlikeleri algılayarak ev sakinlerini korur.</p>
                <br />
                <p><span style={{fontWeight:600}}>Ev Güvenlik Sistemleri:</span> İşyerlerinde kullanılan güvenlik sistemleri, işyerindeki çalışanları, müşterileri ve mülkü korumak için kullanılır. CCTV kameraları, alarm sistemleri ve erişim kontrol sistemleri gibi çözümler kullanılabilir.</p>
                <br />
                <p><span style={{fontWeight:600}}>Araç Güvenlik Sistemleri:</span> Araçlarda kullanılan güvenlik sistemleri, aracın izinsiz kullanılmasını önler ve aracın konumunu takip ederek çalınmasını önler.</p>
                <br />
                <p>Güvenlik sistemleri, ev ve işyerlerimizin güvenliğini sağlamak için önemli bir araçtır. Hırsızlık, yangın, gaz kaçağı gibi tehlikeleri algılayarak erken uyarı sağlar ve can ve mal kaybını önler. Gelişen teknolojiyle birlikte, güvenlik sistemleri daha da etkili hale gelmiş ve yaşamımızı daha güvenli hale getirmiştir. Herkesin ev ve işyerlerini korumak için güvenlik sistemlerinden faydalanması önemlidir.</p>
            </div>
            <div className="sidebar">
            <div className="widget">
                <div className="hizmet">
                <h2>Hizmetlerimiz</h2>
                </div>
                <div className="hizmet">
                    <a href="/corlu-elektrikci">
                        <h3>Elektrik Çözümleri</h3>
                    </a>
                </div>
                <div className="hizmet">
                    <a href="/elektronik-cozumleri">
                        <h3>Elektronik Çözümleri</h3>
                    </a>
                </div>
                <div className="hizmet">
                    <a href="/otomasyon-cozumleri">
                        <h3>Otomasyon Çözümleri</h3>
                    </a>
                </div>
                <div className="hizmet">
                    <a href="/guvenlik-sistemleri">
                        <h3>Güvenlik Sistemleri</h3>
                    </a>
                </div>
                <div className="hizmet">
                    <a href="/kompanzasyon-sistemleri">
                        <h3>Kompanzasyon Sistemleri</h3>
                    </a>
                </div>
                <div className="hizmet">
                    <a href="/corlu-elektrikci">
                        <h3>Elektrik Çözümleri</h3>
                    </a>
                </div>
            </div>
            <div className="sidebar-icerik">
            <h3 > Size Nasıl Yardımcı Olabiliriz?</h3>
            <p> Hakkımızda daha fazla bilgi almak isterseniz bizimle iletişime geçebilirsiniz.</p>
            <a href="tel:05424704215" style={{display:'inline'}}>
                <button className='btn' data-label="İletişime Geç"> İletişime Geç
                </button>

            </a>
            </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default Guvenlik