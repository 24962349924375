import React from 'react'
import './Icons.css'
import ElektrikKablo from '../Assets/elektrik-kablosu2.png'

const Icons = () => {
  return (
    <div className="icons">
         <img className='elektrik-kablo'  src={ElektrikKablo} alt="" />
        <div className="container">
            <div className="icerik py1">
                <h2 className='syf-bslk pb4'>Çözüm Yaklaşımlarımız</h2>
                <div className="g4">
                    <div className="ozellik-card">
                        <i className="fa-solid fa-toolbox icon"></i>
                        <h3 className='crd-bslk pt2 pb2'>Sorunsuz Tamir</h3>
                        <p className='crd-txt'>Profesyonel ekibimiz, her tamiratı sorunsuz ve hızlı bir şekilde gerçekleştirir.</p>
                    </div>
                    <div className="ozellik-card">
                        <i class="fa-solid fa-user-check icon"></i>
                        <h3 className='crd-bslk pt2 pb2'>Memnun Müşteri</h3>
                        <p className='crd-txt'>Müşteri odaklı yaklaşımımızla, beklentileri karşılayarak memnuniyet sağlıyoruz.</p>
                    </div>
                    <div className="ozellik-card">
                        <i class="fa-solid fa-list-check icon"></i>
                        <h3 className='crd-bslk pt2 pb2'>Garantili Hizmet</h3>
                        <p className='crd-txt'>Müşterilerimize güvenilir ve dayanıklı ürünler sunarak kaliteden ödün vermiyoruz.</p>
                    </div>
                    <div className="ozellik-card">
                        <i class="fa-solid fa-users icon"></i>
                        <h3 className='crd-bslk pt2 pb2'>Profesyonel Ekip</h3>
                        <p className='crd-txt'>Deneyimli ve uzman ekibimizle, projelerinizde güvenilir bir iş ortağıyız.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Icons