import React from 'react'
import './TelButton.css'
import Wp from '../Assets/wp.svg'


const TelButton = () => {
  return (
    <div className='Telbutton'>
        <a href="https://wa.me/05424704215?text=Send20%a20%quote">
            <div>
            
                <img src={Wp} alt="" width={30} />
            </div>
        </a>
    </div>
  )
}

export default TelButton