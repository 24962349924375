import React from 'react'
import './Blog.css'
import BreadCrumps from '../BreadCrumps/BreadCrumps'

const Elektrik = () => {
  return (

    <div>
        <BreadCrumps  page="Elektrik Çözümleri"  baslik="Elektrik Çözümleri" text="Profesyonel, hızlı ve çözüm odaklı yaklaşım."/>
        <div className="container">
          <div className="blog">
            <div className="blog-text">
              <h2 className='mb1'>Elektrik Çözümleri</h2>
                <p>Çorlu Elektrikçi olarak, elektrik alanındaki uzmanlığımız ve deneyimimizle Çorlu ve çevresinde güvenilir elektrik hizmetleri sunuyoruz. Müşterilerimize en üst düzeyde hizmet sunmayı hedefleyen ekibimiz, elektrik ihtiyaçlarınızı karşılamak ve güvenli bir yaşam ortamı sağlamak için titizlikle çalışmaktadır.</p>
                <br />
              <h2 className='mb1'>Elektrik Ustası Nedir ve Hangi Hizmetleri Verir?</h2>
                <p>Elektrik ustaları, elektrik alanında uzmanlaşmış profesyonellerdir. Elektrik tesisatının kurulumu, bakımı ve onarımı gibi konularda uzmanlaşmışlardır. Bu alanlarda sağlıklı ve güvenli bir şekilde çalışmalarını yürüten elektrik ustaları, evlerden işyerlerine, sanayi tesislerinden kamu binalarına kadar geniş bir yelpazede hizmet sunarlar.</p>
                <br />
                <p>Elektrik ustaları, müşterilerinin ihtiyaçlarına yönelik çözümler sunmakla kalmaz, aynı zamanda elektrik sistemlerinin verimliliğini artırmak ve güvenliğini sağlamak için de çaba gösterirler.</p>
                <br />
              <h2 className='mb1'>Bizim Sunduğumuz Hizmetler</h2>

                <p><span style={{fontWeight:600}}>Elektrik Tesisatı Kurulumu ve Bakımı:</span> Elektrik tesisatının doğru ve güvenli bir şekilde kurulması, elektrik sisteminin verimli çalışması için hayati öneme sahiptir. Çorlu Elektrikçi olarak, evlerden işyerlerine, sanayi tesislerinden kamu binalarına kadar her türlü elektrik tesisatı kurulumu ve bakımını titizlikle gerçekleştiriyoruz.</p>
                <br />
                <p><span style={{fontWeight:600}}>Arıza Onarımı:</span> Elektrik sistemlerinde meydana gelen arızalar, hayati öneme sahip olabilir ve hızlı bir şekilde çözülmesi gerekebilir. Uzman ekibimiz, elektrik arızalarını hızlı bir şekilde tespit ederek, etkili çözümler sunar ve müşterilerin günlük yaşamlarını kesintisiz devam ettirmesini sağlar.</p>
                <br />
                <p><span style={{fontWeight:600}}>Güvenlik Sistemleri Entegrasyonu:</span> Güvenlik, elektrikle sıkı sıkıya bağlantılıdır ve güvenlik sistemlerinin doğru bir şekilde kurulması ve entegrasyonu büyük önem taşır. Çorlu Elektrikçi olarak, evlerde ve işyerlerinde güvenliğin sağlanması için gerekli olan alarm sistemleri, kamera sistemleri ve akıllı güvenlik çözümleri gibi sistemlerin kurulumu ve entegrasyonunu profesyonel bir şekilde gerçekleştiriyoruz.</p>
                <br />
                <p>Çorlu Elektrikçi olarak, müşterilerimize en iyi hizmeti sunmak ve elektrik sistemlerinin güvenliğini ve verimliliğini sağlamak için buradayız. Profesyonel ve deneyimli ekibimizle, elektrikle ilgili her türlü ihtiyacınıza çözüm sunmak için sabırsızlıkla bekliyoruz. <a href="tel:05424704215" style={{display:'inline-block',fontWeight:600}}>Bizimle iletişime geçin </a> ve elektrik sistemlerinizin güvenliği ve verimliliği konusunda uzman desteği alın.</p>
            </div>
            <div className="sidebar">
              <div className="widget">
                <div className="hizmet">
                  <h2>Hizmetlerimiz</h2>
                </div>
                <div className="hizmet">
                  <a href="/corlu-elektrikci">
                      <h3>Elektrik Çözümleri</h3>
                  </a>
                </div>
                <div className="hizmet">
                  <a href="/elektronik-cozumleri">
                      <h3>Elektronik Çözümleri</h3>
                  </a>
                </div>
                <div className="hizmet">
                  <a href="/otomasyon-sistemleri">
                      <h3>Otomasyon Sistemleri</h3>
                  </a>
                </div>
                <div className="hizmet">
                    <a href="/guvenlik-sistemleri">
                        <h3>Güvenlik Sistemleri</h3>
                    </a>
                </div>
                <div className="hizmet">
                    <a href="/kompanzasyon-sistemleri">
                        <h3>Kompanzasyon Sistemleri</h3>
                    </a>
                </div>
                <div className="hizmet">
                    <a href="/corlu-elektrikci">
                        <h3>Elektrik Çözümleri</h3>
                    </a>
                </div>
              </div>
              <div className="sidebar-icerik">
              <h3 > Size Nasıl Yardımcı Olabiliriz?</h3>
              <p> Hakkımızda daha fazla bilgi almak isterseniz bizimle iletişime geçebilirsiniz.</p>
              <a href="tel:05424704215" style={{display:'inline'}}>
                <button className='btn' data-label="İletişime Geç"> İletişime Geç
                </button>

              </a>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Elektrik