import { GoogleMap,Marker, MarkerF} from '@react-google-maps/api';
import React ,{useState}from 'react';
import mapStyle from './style.json';
import MarkerYol from '../Assets/yol-tarif.png'
import './Contact.css'

const Map = (props)=>{
    const {isLoaded} = props;
    const containerStyle = {
        width: '100%',
        height: '100%',
       
      };
      
      const center = {
        lat: 41.1557874826341,
        lng: 27.81359457629061,
        };
      const corlu = {
        lat: 41.15102197288951,
        lng: 27.811935438428705,
      }
      const handleMarkerClick = () => {
        // Yönlendirmek istediğiniz URL'yi buraya ekleyin
        window.location.href = "https://g.co/kgs/rpLweNB";
    };
    const [markers, setMarkers] = useState([]);
        return isLoaded && (
            <>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={corlu}
              zoom={15}
              options={{
                disableDefaultUI: true,
                scrollwheel: false,
                styles:mapStyle,
              
                
              }}
              >
               

           
            <MarkerF
              position={corlu}
              title={'İnallar Elektrik'}
             
              icon={{
                url: MarkerYol,
               
              }}
              onClick={handleMarkerClick}
              className="marker"
            />
          
               
            </GoogleMap>
            </>
            
            )
            
}
export default Map;