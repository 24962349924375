import ReactReact,{useLayoutEffect,useEffect} from 'react'
import './Home.css'
import { Helmet,HelmetProvider } from 'react-helmet-async'
import Service from '../Service/Service'
import Icons from '../Icons/Icons'
import Featured from '../Featured/Featured'
import Banner from '../Banner/Banner'
import CircleYSol from '../Assets/1.png'
import CircleYSag from '../Assets/2.png'
import CircleASol from '../Assets/3.png'
import CircleASag from '../Assets/4.png'


import gsap from 'gsap'
import { useGSAP } from '@gsap/react'




const Home = () => {
  
  useGSAP(()=>{
    const tl = gsap.timeline();  
    tl.from(
      ".b1",
      {
        opacity:0,
        scale:0.8,
        duration:1,
      }
    )
    tl.from(
      ".b2",
      0.5,
      {
        opacity:0,
        scale:0.8,
        duration:1,
      }
    )
    tl.from(
      ".btn",
     0.5,
      {
        opacity:0,
        scale:0.8,
        duration:1,
      }
    )
  // tl.from('#sagust',{opacity:1,scale:.8})
  })

  return (
    <HelmetProvider>
      <section>
        <a></a>
        <Helmet>
              <title>Anasayfa | Çorlu İnallar Elektrik Elektronik </title>
              <meta property="og:description" content="İnallar Elektrik, Elektronik ve Otomasyon olarak Firmamız,Çorlu başta olmak üzere tüm Trakya bölgesine hizmet vermektedir. Elektrik ve elektronik alanında uzmanlaşmıştır. Elektrik ve elektronik sistemlerin tasarımı, kurulumu ve bakımı konularında geniş bir yelpazede hizmet sunmaktayız. Müşterilerimize güvenilir, yenilikçi ve çözüm odaklı yaklaşımlarla hizmet veriyoruz. Detaylı bilgi için sitemizi ziyaret edin."></meta>
        </Helmet>
        <div className="home">
          <div className='container g2'>

            <div className='home-text'>
              <h1 className='pb2 b1'>Elektrik Ve Otomasyonun Yenilikçi Çözümleri</h1>
              <p className='b2'>İnallar Elektrik olarak edindiğimiz kalite ve vizyon politikası ile, her gün bir adım daha ilerideyiz. Gelişen ve geliştiren Türkiye için!</p>
              <a href="tel:05424704215" style={{display:'inline'}}>
                <button className='btn' data-label="İletişime Geç"> İletişime Geç
                </button>

              </a>
            </div>
            <div className="home-banner" >
              <div className="banners">
                <div style={{display:'flex'}}>
                  <img className='circle 'id='solust' src={CircleYSol} alt="" />
                  <img className='circle' id='sagust' src={CircleYSag} alt=""  />
                </div>
                <div style={{display:'flex'}}>
                  <img className='circle 'id='solalt' src={CircleASol} alt=""  />
                  <img className='circle 'id='sagalt' src={CircleASag} alt=""  />

                </div>

              </div>
            </div>
          </div>
            <section id="section04" className="demo">
              <a href="#services"><span></span></a>
            </section>
        </div>


      </section>
      <Service/>
      <Featured/>
      <Banner/>
      <Icons/>
    </HelmetProvider>
  )
}

export default Home