import React from 'react'
import './Services.css'
import BreadCrumps from '../BreadCrumps/BreadCrumps'
import { Helmet,HelmetProvider } from 'react-helmet-async'

const Services = () => {
  return (
    <HelmetProvider>
    <Helmet>
          <title>Hizmetlerimiz | Çorlu İnallar Elektrik Elektronik </title>
          <meta property="og:description" content="İnallar Elektrik, Çorlu Elektrikçi olarak Firmamız,Çorlu başta olmak üzere tüm Trakya bölgesine hizmet vermektedir. Elektrik ve elektronik alanında uzmanlaşmıştır. Elektrik ve elektronik sistemlerin tasarımı, kurulumu ve bakımı konularında geniş bir yelpazede hizmet sunmaktayız. Müşterilerimize güvenilir, yenilikçi ve çözüm odaklı yaklaşımlarla hizmet veriyoruz. Detaylı bilgi için sitemizi ziyaret edin."></meta>
    </Helmet>
    <div>
          <BreadCrumps page="Hizmetlerimiz"  baslik="Hizmetlerimiz" text="Profesyonel, hızlı ve çözüm odaklı yaklaşım."/>
    <div className="container">
        <div className="service-wrapper">
            <div className="services">
                <h1>Hizmetlerimiz <span></span></h1>
                <div className="services-cards">
                    <a href="/corlu-elektrikci">
                        <div className="service-card">
                            <i class="fa-solid fa-bolt"></i>
                            <h2>Elektrik Çözümleri</h2>
                            <p>Güvenilir elektrik çözümleri sunarak, tecrübeli kadromuz ve kalite standartlarımızla müşterilerimize elektrik hizmetleri sağlıyor ve güvenlerini kazanıyoruz. </p>
                        </div>
                    </a>
                    <a href="/elektronik-cozumleri">
                        <div className="service-card">
                            <i class="fa-solid fa-microchip"></i>
                            <h2>Elektronik Çözümleri</h2>
                            <p>Güvenilir elektronik çözümleri sunarak, sektördeki uzmanlığımız ve kalite standartlarımızla müşterilerimize kesintisiz hizmet sağlıyor ve güvenlerini kazanıyoruz. </p>
                        </div>
                    </a>
                    <a href="/otomasyon-sistemleri">
                        <div className="service-card">
                            <i class="fa-solid fa-gears"></i>
                            <h2>Otomasyon Sistemleri</h2>
                            <p>Elektronik otomasyon çözümleriyle, sektördeki uzmanlığımız ve kalite standartlarımızla müşterilerimize güvenilir ve kesintisiz hizmet sunuyoruz. </p>
                        </div>
                    </a>
                    <a href="/guvenlik-sistemleri">
                        <div className="service-card">
                            <i class="fa-solid fa-shield-halved"></i>
                            <h2>Güvenlik Sistemleri</h2>
                            <p>Güvenlik sistemleri konusunda uzmanlaşmış ekibimiz ve kalite odaklı yaklaşımımızla müşterilerimize en güvenilir çözümleri sunuyoruz </p>
                        </div>
                    </a>
                    <a href="/kompanzasyon-sistemleri">
                        <div className="service-card">
                            <i className='fa-brands fa-chromecast'></i>
                            <h2>Kompanzasyon Sistemleri</h2>
                            <p>Güçlü deneyimimiz ve kalite anlayışımızla, kompanzasyon sistemlerinde güvenilir çözümler sunuyoruz </p>
                        </div>
                    </a>
                    <a href="">
                        <div className="service-card">
                            <i className='fa-brands fa-chromecast'></i>
                            <h2>Elektrik Çözümleri</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem
                                harum illo qui. Ipsam, perspiciatis tempore. </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    </div>
    </HelmetProvider>
  )
}

export default Services