import React,{useState,useCallback,useRef,useEffect} from 'react'
import BreadCrumps from '../BreadCrumps/BreadCrumps'
import { Helmet,HelmetProvider } from 'react-helmet-async'
import emailjs from '@emailjs/browser'

import Map from './Map';
import './Contact.css'


import { useJsApiLoader } from '@react-google-maps/api';



const Contact = () => {
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [number,setNumber] = useState('');
    const [konu,setKonu] = useState('');
    const [notification, setNotification] = useState({ message: '', type: '' });
    const [loading, setLoading] = useState(false); 
  
    useEffect(() => {
        if (notification.message) {
            const timeout = setTimeout(() => {
                setNotification({ message: '', type: '' });
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [notification]);

    
    const form = useRef();
    
      
    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoading(true);

        const serviceId ='service_yj2rgsc';
        const templateId ='template_3k5sa7j';
        const publicKey ='mWWx5bhpbcjqoNOAe';

        const templateParams ={
            from_name:name,
            from_email:email,
            to_name:'İnallar Elektrik',
            message:message,
            number:number,
            konu:konu,
        };
        
       
           


        //send email usein emailJs
        emailjs.send(serviceId,templateId,templateParams,publicKey)
        .then((response) =>{
            console.log('E-mail başarıyla gönderildi !',response);
            setNotification({ message: 'E-posta başarıyla gönderildi.', type: 'success' });
            setName('');
            setEmail('');
            setMessage('');
            setNumber('');
            setKonu('');
        })
        .catch((error)=>{
            console.error('Mesaj Gönderilemedi:',error)
            setNotification({ message: 'E-posta gönderilirken bir hata oluştu.', type: 'error' });
        })
        .finally(() => {
            setLoading(false); // Gönderim tamamlandı   ğında loading durumunu devre dışı bırak
        });
        
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBjPd4e9ULXxrNK5RfAVNxxUqbOOIqfsRA"
      })
    




  return (
    <HelmetProvider>
      <Helmet>
            <title>İletişim | Çorlu İnallar Elektrik Elektronik </title>
            <meta property="og:description" content="İnallar Elektrik, Çorlu Elektrikçi olarak Firmamız,Çorlu başta olmak üzere tüm Trakya bölgesine hizmet vermektedir. Elektrik ve elektronik alanında uzmanlaşmıştır. Elektrik ve elektronik sistemlerin tasarımı, kurulumu ve bakımı konularında geniş bir yelpazede hizmet sunmaktayız. Müşterilerimize güvenilir, yenilikçi ve çözüm odaklı yaklaşımlarla hizmet veriyoruz. Detaylı bilgi için sitemizi ziyaret edin."></meta>
      </Helmet>
    <div className="contact">
        <BreadCrumps page="İletişim"  baslik="İletişim" text="Profesyonel, hızlı ve çözüm odaklı yaklaşım."/>
        <div className="container g3">
            <div style={{zIndex:2}} className='w50 w100'>
                <div className='ch50 ch0' >
                    <h2 className='mb3 syf-bslk '>Bizimle İletişime Geçin</h2>
                    <p><span className='txt2'  style={{fontWeight:'800', display:'inline-block'}}>Günün 24 saati hizmetinizdeyiz</span> ve bize ulaşabilirsiniz.
                        Acil durumlarda problemleri çözmek için elimizden geleni yapıyoruz.
                    </p>
                    <div className='mt3' style={{display:'block'}}>
                        <p><i class="fa-solid fa-phone pr1 mb2"></i> 0542 470 4215</p>
                        <div className="sep-horizontal"></div>
                        <p><i class="fa-solid fa-location-dot pr1 mb2 mt2"></i> Çorlu / Tekirdağ</p>
                        <div className="sep-horizontal"></div>
                        <p><i class="fa-solid fa-envelope pr1 mt2 "></i>info@inallarelektrik.com</p>
                        
                    </div>
                </div>
            </div>
            <div class="formbold-main-wrapper w50 w100 " >
                <div class="formbold-form-wrapper">
                <h2 className='mb3 bslk'> İletişim Formu</h2>
                    <form ref={form}  onSubmit={handleSubmit} >
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label"> </label>
                        <input
                        required
                        value={name}
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Ad Soyad"
                        class="formbold-form-input"
                        onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div class="formbold-mb-5">
                        <label for="firma" class="formbold-form-label"></label>
                        <input
                        required
                        value={konu}
                        type="name"
                        name="name"
                        id="name"
                        placeholder="Firma Adı"
                        class="formbold-form-input"
                        onChange={(e) => setKonu(e.target.value)} 
                        />
                    </div>
                    
                    <div class="formbold-mb-5">
                        <label for="email" class="formbold-form-label"></label>
                        <input
                        required
                        value={email}
                        type="email"
                        name="email"
                        id="email"
                        placeholder="E-Posta"
                        class="formbold-form-input"
                        onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div class="formbold-mb-5">
                        <label for="message" class="formbold-form-label"> </label>
                        <textarea
                        required
                        value={message}
                        rows="6"
                        name="message"
                        id="message"
                        placeholder="İletmek İstediğiniz Mesaj"
                        class="formbold-form-input"
                        style={{backgroundColor:'transparent'}}
                        onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                    </div>
                   
                        

                    <div>
                        
                        <button type="submit" className="formbold-btn">
                        {loading && <div class="dot-spinner">
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                            <div class="dot-spinner__dot"></div>
                        </div>}
                        {!loading && notification.message && <span>{notification.message}</span>}
                        {!loading && !notification.message && <span>Gönder</span>}
                </button>
                    </div>
                    </form>
                </div>
            </div>

            {/* <div className="maps">
                <div className="pointer">
                    <a href="https://g.co/kgs/rpLweNB"><i class="fa-solid fa-location-dot"></i></a>
                </div>
            </div> */}
           <div className='maps w13 '>
                <div className="harit">
                    <Map isLoaded={isLoaded}/>

                </div>
            </div>
        </div>
        <div style={{height:'40vh'}}></div>
            <div className='mobile-map w13 '>
                <div className="mobile-harit">
                    <Map isLoaded={isLoaded}/>

                </div>
            </div>
    </div>
    </HelmetProvider>
  )
}

export default Contact