import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// Pages
import Home from "./components/Home/Home";
import Footer from "./components/Layout/Footer";
import Navbar from "./components/Layout/Navbar";
import WpButton from "./components/WpButton/WpButton";
import TelButton from "./components/WpButton/TelButton";
import Aboutpage from "./components/Aboutpage/Aboutpage";
import Services from "./components/Services/Services";
import Contact from "./components/Contact/Contact";
import Elektrik from "./components/Blog/Elektrik";
import Elektronik from "./components/Blog/Elektronik";
import Otomasyon from "./components/Blog/Otomasyon";
import Guvenlik from "./components/Blog/Guvenlik";
import Kompanzasyon from "./components/Blog/Kompanzasyon";
import P404 from "./components/P404/P404";

const router = createBrowserRouter([
  {
    path:'/',
    element:<Navbar />,
    children:[
    {index:true,element: (<Home />),},
    {path: "/anasayfa",element: (<Home />),},
    {path: "/hakkimizda",element: (<Aboutpage />),},
    {path: "/iletisim",element: (<Contact />),},
    {path: "/hizmetlerimiz",element: (<Services />),},
    {path: "/corlu-elektrikci",element: (<Elektrik />),},
    {path: "/elektronik-cozumleri",element: (<Elektronik />),},
    {path: "/otomasyon-sistemleri",element: (<Otomasyon />),},
    {path: "/guvenlik-sistemleri",element: (<Guvenlik />),},
    {path: "/kompanzasyon-sistemleri",element: (<Kompanzasyon />),},
    {path: "/404",element: (<P404 />),},
    

    // {path: "/foto-galeri",element: (<Images />),},
    // {path: "/video-galeri",element: (<VideoGallery />),},
    
  ]
  }
])

function App() {
  return (
    <div>
      <div>
        <RouterProvider router={router} />
        
        <Footer/>
        <WpButton/>
        <TelButton/>
    </div>
    </div>
  );
}

export default App;
