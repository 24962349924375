import React from 'react'
import './Footer.css'
import Logo from '../Assets/inallarlogo.png'

const Footer = () => {
  return (
    <footer className="footer mt4 pt3">
      <div className="footer-top container g3">
            <div className="footer-logo">
                <img src={Logo} alt="" width='300px' />
                <ul className='pt2'>
                  <li><a href="https://www.instagram.com/inallar_elektrik/"><i class="fa-brands fa-instagram"></i></a></li>
                  <li><a href=""><i class="fa-brands fa-x-twitter"></i></a></li>
                  <li><a href=""><i class="fa-brands fa-facebook-f"></i></a></li>
                </ul>
            </div>
           
            <div className="footer-contact">
              <h3>Hizmetlerimiz</h3>
              <ul>
                <li><a href="">Elektrik</a></li>
                <li><a href="">Elektronik</a></li>
                <li><a href="">Otomasyon</a></li>
                <li><a href="">Güvenlik Sistemleri</a></li>
              </ul>
            </div>
            <div className="footer-contact ">
              <h3>İletişim</h3>
              <ul className='social'>
                <li><i class="fa-solid fa-location-dot"></i>Elektriğin Olduğu Her Yer.</li>
                <li><a href="tel:05424704215"><i class="fa-solid fa-phone"></i>+(90) 542 470 4215</a></li>
                <li><a href="mail:info@inallarelektrik.com"><i class="fa-solid fa-envelope"></i>info@inallarelektrik.com</a></li>
              </ul>
            </div>
      </div>
       
        <div className="seperator"></div>
        <hr />
      <div className="copyright">
        <p>Tüm Hakları Saklıdır &copy; İnallar Elektrik ve Elektronik </p>
      </div>
    </footer>
  )
}

export default Footer