import React from 'react'
import './Service.css'
import ElektrikKablo from "../Assets/elektrik kablosu.png"


const Service = () => {
  return (
    <div id='services' className="service">
        <img className='elektrik-kablo' src={ElektrikKablo} alt="" />
      <div className='container'>
        <div className="icerik py3">
          <h1 className='pb4 syf-bslk'>Hizmetlerimiz</h1>
          <div className="hizmetler">
            <div className="service-cards g4 py2">
              <div className="card p3 py4">
                <i class="fa-solid fa-bolt"></i>
                <h3 className='pb2'>Elektrik Çözümleri</h3> 
                <p className='crd-txt'>Güvenilir elektrik çözümleriyle kalite ve güven garantisi veriyoruz.</p> 
              </div>
              <div className="card p3 py4">
                <i class="fa-solid fa-microchip"></i>
                <h3  className='pb2'>Elektronik Çözümleri</h3>
                <p className='crd-txt'>Yenilikçi elektronik çözümleriyle geleceği birlikte şekillendiriyoruz.</p>
              </div>
              <div className="card p3 py4">
                <i class="fa-solid fa-gears"></i>
                <h3  className='pb2'>Otomasyon Sistemi</h3>
                <p className='crd-txt'>Verimliliği artıran otomasyon sistemleri ile işlerinizi kolaylaştırın.</p>  
              </div>
              <div className="card p3 py4">
                <i class="fa-solid fa-shield-halved"></i>
                <h3  className='pb2'>Güvenlik Sistemleri</h3>
                <p className='crd-txt'>Güvenlik için yenilikçi elektronik çözümler sunuyoruz.</p>  
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Service