import React from 'react'
import './WpButton.css'
import Tel from '../Assets/tel.svg'

const WpButton = () => {
  return (
    <div className='WpButton'>
        <a href="tel:05424704215">
            <div>
                <img src={Tel} alt="" width={30}/>
            </div>
        </a>
    </div>
  )
}

export default WpButton