import React from 'react'
import BreadCrumps from '../BreadCrumps/BreadCrumps'
import ElektrikKablo from "../Assets/elektrik kablosu.png"
import './Aboutpage.css'
import { Helmet,HelmetProvider } from 'react-helmet-async'
const Aboutpage = () => {
  return (
    <HelmetProvider>
      <Helmet>
            <title>Hakkımızda | Çorlu İnallar Elektrik Elektronik </title>
            <meta property="og:description" content="İnallar Elektrik, Çorlu Elektrikçi olarak Firmamız,Çorlu başta olmak üzere tüm Trakya bölgesine hizmet vermektedir. Elektrik ve elektronik alanında uzmanlaşmıştır. Elektrik ve elektronik sistemlerin tasarımı, kurulumu ve bakımı konularında geniş bir yelpazede hizmet sunmaktayız. Müşterilerimize güvenilir, yenilikçi ve çözüm odaklı yaklaşımlarla hizmet veriyoruz. Detaylı bilgi için sitemizi ziyaret edin."></meta>
      </Helmet>
    <div className='Aboutpage'>
        <BreadCrumps page="Hakkımızda"  baslik="Kuruluşumuzdan Beri Elektrik Dünyasında Profesyonel Hizmet " text="Profesyonel, hızlı ve çözüm odaklı yaklaşım."/>
        <img className='elektrik-kablo' src={ElektrikKablo} alt="" />
        <div className="container">
            <div className="about">
                <h1 className='pb4 syf-bslk'>Bizi Tanıyın</h1>
                <p className='txt2'>
                    İnallar Elektrik, 2018 yılında Çorlu'da kurulmuş olup tüm Trakya bölgesine hizmet veren elektrik, elektronik ve otomasyon alanlarında faaliyet gösteren profesyonel bir şirkettir. Kuruluşumuz, sektördeki deneyimli ve uzman kadrosuyla, müşterilerine en iyi hizmeti sunmayı amaçlamaktadır. <br /> <br />

                    Bizler, elektrik, elektronik ve otomasyon alanlarında uzmanlaşmış bir ekip olarak, müşterilerimize yenilikçi çözümler sunmaktan ve ihtiyaçlarına yönelik en uygun çözümleri sağlamaktan gurur duyuyoruz. Teknolojideki gelişmeleri yakından takip ediyor ve müşterilerimize en son teknolojiyi ve en iyi uygulamaları sunarak işlerini daha verimli hale getirmelerine yardımcı oluyoruz.<br /><br />

                    İnallar Elektrik olarak, kaliteye ve müşteri memnuniyetine verdiğimiz önemi her zaman en üst düzeyde tutuyoruz. Sektördeki güvenilirliğimiz ve uzmanlığımızla, müşterilerimizin ihtiyaçlarına uygun çözümler sunarak uzun vadeli iş birlikleri kurmayı hedefliyoruz.<br /><br />

                    Müşterilerimizin memnuniyetini her zaman ön planda tutarak, elektrik, elektronik ve otomasyon alanında sunduğumuz hizmetlerle Trakya bölgesinde öncü olmaya devam edeceğiz.</p>
            </div>
           
        </div>
        <div style={{paddingBottom:"1px"}}>
                    <div className="container" style={{marginBottom:'6rem'}}>
                        <div className='vm-field'>
                            <div className="vyznSplit"></div>
                            <div className="vzyn-field">
                            <div className="vizyon-group" data-aos='fade-up' data-aos-duration='500' data-aos-anchor-placement="top-bottom">
                                <h1>Vizyon</h1>
                                <p className='txt2'>İnallar Elektrik olarak, sektörde lider bir konuma yükselerek müşterilerimize en üstün kalitede hizmet sunmayı ve sürekli olarak teknolojik yeniliklerle ilerleyerek sektördeki standartları belirlemeyi hedefliyoruz.</p>
                            </div>
                            <div className="vizyon-group" data-aos='fade-up' data-aos-duration='700' data-aos-anchor-placement="top-bottom">
                                <h1>Misyon</h1>
                                <p className='txt2'>Müşteri memnuniyetini odak noktamız olarak belirleyerek, elektrik, elektronik ve otomasyon alanlarında uzman ekibimizle birlikte en iyi çözümleri sunarak müşterilerimizin beklentilerini aşmayı ve uzun vadeli iş ilişkileri kurmayı amaçlıyoruz.</p>

                            </div>
                        </div>
                    </div>
        </div>
        </div>
    </div>
    </HelmetProvider>
  )
}

export default Aboutpage