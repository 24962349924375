import React,{useEffect,useState,useRef} from 'react'
import gsap from 'gsap';
import "./BreadCrumps.css"

import ScrollTrigger from 'gsap/ScrollTrigger';







const BreadCrumps = (props) => {

    
    useEffect(()=>{
        let mm = gsap.matchMedia();
      mm.add("(min-width:768px)",()=>{
        gsap.to("#top", {
          y: -40,
          duration: 1,
          scrollTrigger: {
            trigger: ".breadText",
            start: "top 30%",
            end: "bottom 200px",
            scrub: 1,
           
          }
        });
      })
      return () => mm.revert();
      })


      const bottomRef = useRef(null);

      const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' , block: 'end'});
      };
      
  return (
    <div className='breadCrumps' >
        
    <div className='container' >
        <div id='top'>
            <div className='breadText'  >
                <div className='breadTexts'>
                    <a href="/">Anasayfa</a>
                    <div className='breadSplit'></div>
                    <p>{props.page}</p>

                </div>
                <h2 className='headerbaslik'>{props.baslik} </h2>
                <h4 className='sloganText'> {props.text}</h4>
                
            </div>
        </div>
    </div>
    <div id="scroll-down-animation" onClick={scrollToBottom}>
      
        <span class="mouse" ref={bottomRef}>
            <span class="move"></span>
        </span>
        
    </div>
</div>


  )
}

export default BreadCrumps