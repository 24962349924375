import React,{useState,useRef,useEffect} from 'react'
import './Navbar.css'
import {Outlet } from 'react-router-dom'
import İnallarLogo from '../Assets/inallarlogo.png'




const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [navBarActive, setNavBarActive] = useState(false);
    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };



    const handleHamburgerClick = () => {
    setNavBarActive(!navBarActive);
    setIsHamburgerOpen(!isHamburgerOpen);
    };
    const navRef = useRef();
    const hamburgerRef = useRef();
    
    const handleClickOutside = (event) => {
        if (
            (navRef.current && !navRef.current.contains(event.target)) &&
            (hamburgerRef.current && !hamburgerRef.current.contains(event.target))
        ) {
            setNavBarActive(false);
            setIsHamburgerOpen(false);
        }
    };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


    const body = document.body;
    let lastScroll = 0;
  
    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 70) {
        body.classList.remove("scroll-up");
        return;
      }
  
      if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
        body.classList.remove("scroll-up");
        body.classList.add("scroll-down");
      } else if (
        currentScroll < lastScroll &&
        body.classList.contains("scroll-down")
      ) {
        body.classList.remove("scroll-down");
        body.classList.add("scroll-up");
      }
      lastScroll = currentScroll;
    });
       
        return (
            <div>
                <header className='head '>
                    
                   
                    <nav className='container desktop-nav'>
                        <ul>
                            <li><a className='active' href="/">Anasayfa</a></li>
                            <li><a className='active' href="/hizmetlerimiz">Hizmetlerimiz</a></li>
                            <div className="mobile">
                              <div className="logo"><a href="/"><img src={İnallarLogo} alt="İnallar Elektrik Logo" width='250' height='200'/></a></div>
                              <div className="burger">
                              <div className={`menu-container ${isOpen ? 'menu-open' : ''}`}>
                                <div className="vbp-header-menu-button__svg" onClick={toggleMenu}>
                                  <div className="line top"></div>
                                  <div className="line middle"></div>
                                  <div className="line bottom"></div>
                                </div>
                                <div className="menu-items">
                                <li><a className='active' href="/">Anasayfa</a></li>
                                <li><a className='active' href="/hizmetlerimiz">Hizmetlerimiz</a></li>
                                <li><a className='active' href="/hakkimizda">Hakkımızda {/*<i  class="fa-solid fa-caret-down"></i>*/}</a></li>
                                <li><a className='active' href="/iletisim">İletişim</a></li>
                                </div>
                              </div>
                              </div>

                            </div>
                            <li><a className='active' href="/hakkimizda">Hakkımızda {/*<i  class="fa-solid fa-caret-down"></i>*/}</a></li>
                            <li><a className='active' href="/iletisim">İletişim</a></li>
                        </ul>

                     
                    </nav>
                          
                      
                </header> 
               {/* mobile */}
                <header className='mobile-head'>
            
            <div className="logo"><a href="/"><img src={İnallarLogo} alt="İnallar Elektrik Logo" width='250' height='200'/></a></div>

            <div ref={hamburgerRef} className={`hamburger ${isHamburgerOpen ? 'open' : ''}`} onClick={handleHamburgerClick}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </div>
        <nav ref={navRef} className={`nav-bar ${navBarActive ? 'active' : ''}`}>
            <ul >
                <li><a className='active' href="/">Anasayfa</a></li>
                <li><a className='active' href="/hizmetlerimiz">Hizmetlerimiz</a></li>
                <li><a className='active' href="/hakkimizda">Hakkımızda </a>
                </li>
                
                <li><a className='active' href="/iletisim">İletişim</a></li>
                
            </ul>
        </nav>
          
           
    </header>        
                       
        
                <main>
                    <Outlet/>
                </main>
            </div>
    
  )
}

export default Navbar